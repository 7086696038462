import React from 'react';
import CurrencyInput from 'react-currency-input-field';

const CurrencyField = (props) => {
    return <div id={props.id} style={{maxWidth: '400px', marginBottom: '20px'}}>
        <span>{props.labelText}</span>
        {props.tooltip && props.tooltip}
        <div className='pds-input-icon-left' style={{marginTop: '8px'}}>
            <CurrencyInput
                id={`${props.id}CurrencyInput`}
                name={props.labelText}
                aria-label={props.labelText}
                defaultValue={props.value}
                maxLength={10}
                decimalsLimit={2}
                onValueChange={props.onChange}
            />
            <i data-feather='dollar-sign' aria-hidden='true' width='16px'/>
        </div>
    </div>;
};

export default CurrencyField;