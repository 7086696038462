import {combineReducers} from 'redux';
import companyInfoReducer from './CompanyInfoReducer';
import lifeInsuranceReducer from './LifeInsuranceReducer';
import dentalInsuranceReducer from './DentalInsuranceReducer';
import pageReducer from './PageReducer';
import retirementReducer from './RetirementReducer';
import longTermDisabilityReducer from './LongTermDisabilityReducer';
import resultsReducer from './ResultsReducer';
import shortTermDisabilityReducer from './ShortTermDisabilityReducer';
import idReducer from './IdReducer';

const reducer = () => combineReducers({
    id: idReducer,
    companyInfo: companyInfoReducer,
    dentalInsurance: dentalInsuranceReducer,
    lifeInsurance: lifeInsuranceReducer,
    longTermDisability: longTermDisabilityReducer,
    page: pageReducer,
    results: resultsReducer,
    retirement: retirementReducer,
    shortTermDisability: shortTermDisabilityReducer
});

export default reducer;