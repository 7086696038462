import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import '@pds/calloutSection/calloutSection.min.js';
import '@pds/checkbox/checkbox.min.js';
import '@pds/core/core.min.js';
import '@pds/collapsible/collapsible.min.js';
import '@pds/enterpriseFooter/enterpriseFooter.min.js';
import '@pds/enterpriseHeader/enterpriseHeader.min.js';
import '@pds/dataTable/dataTable.min.js';
import '@pds/erroredInput/erroredInput.min.js';
import '@pds/erroredSelect/erroredSelect.min.js';
import '@pds/alert/alert.min.js';
import '@pds/form/form.min.js';
import '@pds/icon/icon.min.js';
import '@pds/imgFacebook/imgFacebook.min.js';
import '@pds/imgLogoWhiteRight/imgLogoWhiteRight.min.js';
import '@pds/labeledInput/labeledInput.min.js';
import '@pds/labeledSelect/labeledSelect.min.js';
import '@pds/list/list.min.js';
import '@pds/modal/modal.min.js';
import '@pds/navigationPrimary/navigationPrimary.min.js';
import '@pds/select/select.min.js';
import '@pds/superFooter/superFooter.min.js';
import '@pds/table/table.min.js';
import '@pds/validations/validations.min.js';
import '@pds/skeletonLoader/skeletonLoader.min.js';
import '@pds/graphicSkeletonLoader/graphicSkeletonLoader.min.js';
import '@pds/calloutSection/calloutSection.min.css';
import '@pds/checkbox/checkbox.min.css';
import '@pds/core/core.min.css';
import '@pds/collapsible/collapsible.min.css';
import '@pds/enterpriseFooter/enterpriseFooter.min.css';
import '@pds/enterpriseHeader/enterpriseHeader.min.css';
import '@pds/dataTable/dataTable.min.css';
import '@pds/erroredInput/erroredInput.min.css';
import '@pds/erroredSelect/erroredSelect.min.css';
import '@pds/alert/alert.min.css';
import '@pds/form/form.min.css';
import '@pds/icon/icon.min.css';
import '@pds/imgFacebook/imgFacebook.min.css';
import '@pds/imgLogoWhiteRight/imgLogoWhiteRight.min.css';
import '@pds/labeledInput/labeledInput.min.css';
import '@pds/labeledSelect/labeledSelect.min.css';
import '@pds/list/list.min.css';
import '@pds/modal/modal.min.css';
import '@pds/navigationPrimary/navigationPrimary.min.css';
import '@pds/select/select.min.css';
import '@pds/superFooter/superFooter.min.css';
import '@pds/table/table.min.css';
import '@pds/validations/validations.min.css';
import '@pds/skeletonLoader/skeletonLoader.min.css';
import '@pds/graphicSkeletonLoader/graphicSkeletonLoader.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserHistory} from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import reducer from './redux/reducer/Reducer';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';

const history = createBrowserHistory();

const store = createStore(
    reducer(),
    compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App history={history}/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
