import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ContactUs from './ContactUs';
import {COMPANY_INFO_PAGE, UPDATE_PAGE} from '../../redux/reducer/PageReducer';
import CoverageDropdown from '../form/common/CoverageDropdown';
import Button from '../form/common/Button';
import {stateSelector} from '../../redux/selectors/StateSelector';
import Error from './Error';
import DentalForm from '../form/DentalForm';
import ShortTermDisabilityForm from '../form/ShortTermDisabilityForm';
import LifeInsuranceForm from '../form/LifeInsuranceForm';
import RetirementForm from '../form/RetirementForm';
import LongTermDisabilityForm from '../form/LongTermDisabilityForm';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const ResultsView = () => {

    const dispatch = useDispatch();
    const store = useSelector(stateSelector);

    const [state, setState] = React.useState({
        isActive: false
    });

    React.useEffect(() => {
        state.isActive && window.print();
        setState({isActive: false});
    }, [state.isActive]);

    return (<div className='container-fluid'>
            <div className='row' style={{margin: '40px 20px 0px 20px'}}>
                <div className='col-md-12'>
                    <img className='hidden showPrint' src='/logo-company-name-gradient.png' alt='Principal logo'
                         style={{'float': 'right'}}/>
                </div>
                <div className='col-md-6 offset-md-3'>
                    {Boolean(store.results.error)
                        ? <Error/>
                        : <>
                            <h2>Check out the results from Principal<sup>®</sup> Benefit Design Tool!</h2>
                            <p>View each section to see common benefit features of
                                similarly sized Principal customers in your industry and region.
                            </p>
                            <p className='noPrint'>
                                And if you want a personalized report showing how your benefits stack up to similar businesses,
                                enter your benefit information in each section.
                            </p>
                            <CoverageDropdown id='ResultsViewLifeDropdown'
                                              title='Life insurance'
                                              loading={store.results.loading}
                                              answers={store.lifeInsurance}
                                              results={store.results.lifeInsurance}
                                              isActive={state.isActive}>
                                <p>Life insurance helps employees protect what means the most to them. When employees have life insurance,
                                    they help provide for of the people they love.</p>
                                <LifeInsuranceForm/>
                            </CoverageDropdown>
                            <CoverageDropdown id='ResultsViewDentalDropdown'
                                              title='Dental insurance'
                                              loading={store.results.loading}
                                              answers={store.dentalInsurance}
                                              results={store.results.dental}
                                              isActive={state.isActive}>
                                <p>Dental insurance is a top employee-requested benefit. And when employees have dental coverage, they're
                                    more likely to visit the dentist for preventive care.</p>
                                <DentalForm/>
                            </CoverageDropdown>
                            <CoverageDropdown id='ResultsViewSTDDropdown'
                                              title='Short-term disability insurance'
                                              loading={store.results.loading}
                                              answers={store.shortTermDisability}
                                              results={store.results.shortTermDisability}
                                              isActive={state.isActive}>
                                <p>Offering disability insurance lets employees know you care enough about their future to provide coverage
                                    that helps protect their income if they're too ill or injured to work. The coverage replaces a portion
                                    of
                                    their income so they can focus on taking care of themselves, and worry less about how they’ll pay the
                                    bills.</p>
                                <ShortTermDisabilityForm/>
                            </CoverageDropdown>
                            <CoverageDropdown id='ResultsViewLTDDropdown'
                                              title='Long-term disability insurance'
                                              loading={store.results.loading}
                                              answers={store.longTermDisability}
                                              results={store.results.longTermDisability}
                                              isActive={state.isActive}>
                                <p>Offering disability insurance lets employees know you care enough about their future to provide coverage
                                    that helps protect their income if they're too ill or injured to work. The coverage replaces a portion
                                    of
                                    their income so they can focus on taking care of themselves, and worry less about how they’ll pay the
                                    bills.</p>
                                <LongTermDisabilityForm/>
                            </CoverageDropdown>
                            <CoverageDropdown id='ResultsViewRetirementDropdown'
                                              title='Retirement plan'
                                              loading={store.results.loading}
                                              answers={store.retirement}
                                              results={store.results.retirement}
                                              isActive={state.isActive}>
                                <p>Offering a retirement plan can make a big difference in how much your employees set aside for
                                    retirement. But it’s about more than just savings. In recent years, there's been a shift. A
                                    retirement savings plan is no longer just a “nice to have.” It's now seen as a “need to
                                    have” benefit to remain competitive and keep your employees satisfied.</p>
                                <p>An employer-sponsored retirement plan can help:</p>
                                <ul>
                                    <li>You and your employees save for retirement</li>
                                    <li>Attract and retain top talent</li>
                                    <li>Provide tax advantages for your business and your employees</li>
                                </ul>
                                <p>We've got services for all the major
                                    <a href='https://www.principal.com/employers/employee-benefits/retirement-plans'
                                       style={{margin: '0px 5px'}}>
                                        types of retirement plans.
                                    </a>
                                    And if a qualified retirement plan isn't a fit, you may want to look at retirement solutions like a
                                    Simplified Employee Pension (SEP) IRA or Savings Incentive Match for Employees
                                    (SIMPLE)
                                    IRA.
                                </p>
                                <p>Talk to your financial professional or third party administrator (TPA) and ask how Principal may be able
                                    to help.</p>
                                <RetirementForm/>
                            </CoverageDropdown>
                            <div className='noPrint'>
                                <h3 style={{marginTop: '40px'}}>Ready to put your results into action?</h3>
                                <p>Use your results to help build a better benefits program.</p>
                                <ul>
                                    <li>Print or save a copy so you can refer back to the results.</li>
                                    <li>Share results with other leaders in your organization or your financial professional.</li>
                                </ul>
                                <ContactUs/>
                            </div>
                        </>}
                    <Button
                        id='ResultsViewBackButton'
                        className='pds-button pds-button-primary pds-util-float-left noPrint'
                        back
                        onClick={() => dispatch(
                            {
                                type: UPDATE_PAGE,
                                data: COMPANY_INFO_PAGE
                            }
                        )}
                    >Back
                    </Button>
                    <Button
                        id='ResultsPrintButton'
                        onClick={() => {
                            setState({isActive: true});
                            GoogleAnalyticsUtil.callToAction('Print');
                        }}
                    >
                        <i data-feather='printer' data-size='24' aria-hidden='true'/> Print
                    </Button>
                </div>
                <div id='resultsViewDisclaimer' className='col-md-9' style={{margin: '0 auto', paddingTop: '20px'}}>
                    <p className='hidden showPrint'>benefitdesigntool.principal.com</p>
                    <strong>
                        About the Principal® Benefit Design Tool: Each employee benefit will be compared against similarly sized Principal
                        customers in your industry and region.
                    </strong>
                    <p>
                        This is the average result experienced by defined contribution plans and/or group insurance customers of Principal
                        Life Insurance Company®. Comparison data is updated annually, and results are accurate as of December 31, 2023.
                        Choices made by employers, fiduciaries and participants impact the results. Organizations may consider the average,
                        along with other factors, when deciding which features best suit your needs. We strive to include a minimum of 100
                        plans of similar size in your industry and region.
                    </p>
                    <p>
                        This information is provided for general education and is not intended as a recommendation.
                    </p>
                    <p>
                        This summary is not a complete statement of the rights, benefits, limitations and exclusions of the insurance
                        described here. For cost and coverage details, contact your Principal representative.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ResultsView;