export const TWO_YEARS = '2 years';
export const FIVE_YEARS = '5 years';
export const TO_SIXTY_FIVE = 'To age 65';
export const SSNRA = 'Social Security Normal Retirement Age (SSNRA)';

export const benefitDurations = [
    {display: 'Please select', value: ''},
    {display: TWO_YEARS, value: TWO_YEARS},
    {display: FIVE_YEARS, value: FIVE_YEARS},
    {display: TO_SIXTY_FIVE, value: TO_SIXTY_FIVE},
    {display: SSNRA, value: SSNRA}
];

