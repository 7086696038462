import {getDataLayer} from './dataLayer';

export class GoogleAnalyticsUtil {

    static callToAction = (clickText) => {
        getDataLayer().push({
            'event': 'pageNavigationClick',
            'navigationsection': 'call-to-action',
            'clicktext': clickText
        });
    }

    static modalClickAction = (title, text) => {
        getDataLayer().push({
            'event': 'modalClick',
            'modaltitle': title,
            'clicktext': text
        });
    };

    static modalViewAction = (title) => {
        getDataLayer().push({
            'event': 'modalView',
            'modaltitle': title
        });
    };

    static pageEventAction = (path, pageTitle, eventString) => {
        getDataLayer().push({
            'event': eventString,
            'virtualpagedetails': {
                'pagepath': path,
                'pagetitle': pageTitle,
            },
            'pageLocation': path,
            'content': {
                'purpose': 'retention',
                'businessline': 'benefits-and-protection',
                'intendedaudience': 'employer',
            },
            'page': {
                'fullurl': window.location.href,
                'documenttype': 'webpage',
                'author': '',
                'publishdate': '',
                'compliancedate': '',
                'language': 'english',
                'contractid': ''
            }
        });
    };

    static tocLink = (clickText) => {
        getDataLayer().push({
            'event': 'pageNavigationClick',
            'navigationsection': 'toc-link',
            'clicktext': clickText
        });
    }

    static textLink = (clickText) => {
        getDataLayer().push({
            'event': 'pageNavigationClick',
            'navigationsection': 'text-link',
            'clicktext': clickText
        });
    }
}
