export const UPDATE_LONG_TERM_DISABILITY = 'UPDATE_LONG_TERM_DISABILITY';
const defaultState = {};

const longTermDisabilityReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_LONG_TERM_DISABILITY:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default longTermDisabilityReducer;