export default class CurrencyUtil {

    static NO_DECIMALS = {minimumFractionDigits: 0};

    static formatUSD(value, options) {

        if (!value && value !== 0) {
            return '';
        }

        const currency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            ...options
        });

        return currency.format(value);
    }

}