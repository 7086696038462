export const numeric = (text) => {
    if (Boolean(text)) {
        return text.replace(/[^0-9]/g, '');
    }
    return '';
};

export const appendString = (initial, valueToAppend) => {
    if (Boolean(initial) || initial === 0) {
        return initial + valueToAppend;
    }
};

export const numericWithPeriod = (text) => {
    if (Boolean(text)) {
        return text.replace(/[^0-9.]/g, '');
    }
    return '';
};