export const ZERO_YEARS = '0 years';
export const ONE_YEAR = '1 year';
export const TWO_YEARS = '2 years';
export const THREE_YEARS = '3 years';
export const FOUR_YEARS = '4 years';
export const FIVE_YEARS = '5 years';
export const END_OF_BENEFIT_DURATION = 'End of benefit duration';

export const occupationPeriods = [
    {display: 'Please select', value: ''},
    {display: ZERO_YEARS, value: ZERO_YEARS},
    {display: ONE_YEAR, value: ONE_YEAR},
    {display: TWO_YEARS, value: TWO_YEARS},
    {display: THREE_YEARS, value: THREE_YEARS},
    {display: FOUR_YEARS, value: FOUR_YEARS},
    {display: FIVE_YEARS, value: FIVE_YEARS},
    {display: END_OF_BENEFIT_DURATION, value: END_OF_BENEFIT_DURATION}
];

