import React from 'react';
import DropDownField from './common/DropDownField';
import {useDispatch, useSelector} from 'react-redux';
import {employeeBenefitSchedules} from '../../properties/employeeBenefitSchedules';
import {waiverDurations} from '../../properties/waiverDurations';
import {UPDATE_LIFE_INSURANCE} from '../../redux/reducer/LifeInsuranceReducer';
import {stateSelector} from '../../redux/selectors/StateSelector';
import {spouseBenefitOptions} from '../../properties/spouseBenefitOptions';
import {childBenefitOptions} from '../../properties/childBenefitOptions';
import Button from './common/Button';
import CurrencyField from './common/CurrencyField';
import CurrencyUtil from '../../util/CurrencyUtil';
import Modal from './common/Modal';
import {saveData} from '../../redux/action/SaveDataAction';
import Tooltip from './common/Tooltip';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const LifeInsurance = () => {

    const dispatch = useDispatch();
    const reduxState = useSelector(stateSelector);
    const [state, setState] = React.useState({
        waiverOfPremiumDuration: reduxState.lifeInsurance.waiverOfPremiumDuration,
        employeeBenefitSchedule: reduxState.lifeInsurance.employeeBenefitSchedule,
        spouseBenefit: reduxState.lifeInsurance.spouseBenefit,
        childBenefit: reduxState.lifeInsurance.childBenefit,
        maximumEmployeeBenefit: reduxState.lifeInsurance.maximumEmployeeBenefit
    });

    return (
        <div className='noPrint'>
            <Modal
                id='lifeInsuranceForm'
                buttonText='Compare your insurance'
                headerText='Life insurance:'
                srText='life'
                title='Life insurance'
            >
                <div style={{textAlign: 'left', marginTop: '40px'}}>
                    <DropDownField
                        id='LifeInsuranceBenefitScheduleDropdown'
                        options={employeeBenefitSchedules}
                        value={state.employeeBenefitSchedule}
                        isValid={true}
                        labelText={'What\'s the employee benefit schedule?'}
                        onChange={(e) => setState({...state, employeeBenefitSchedule: e.target.value})}
                        tooltip={<Tooltip content={'amount of life insurance coverage per employee'}/>}
                    />
                    <CurrencyField
                        id='LifeInsuranceMaximumEmployeeBenefit'
                        labelText={'What\'s the maximum employee benefit?'}
                        value={state.maximumEmployeeBenefit}
                        onChange={(value) => setState({...state, maximumEmployeeBenefit: CurrencyUtil.formatUSD(value)})}
                    />
                    <DropDownField
                        id='LifeInsuranceWaiverDurationDropdown'
                        options={waiverDurations}
                        value={state.waiverOfPremiumDuration}
                        isValid={true}
                        labelText={'What\'s the waiver of premium duration?'}
                        onChange={(e) => setState({...state, waiverOfPremiumDuration: e.target.value})}
                        tooltip={<Tooltip content={'This waives life insurance premiums due if the insured person qualifies as totally disabled.'}/>}
                    />
                    <DropDownField
                        id='LifeInsuranceSpouseBenefitDropdown'
                        options={spouseBenefitOptions}
                        value={state.spouseBenefit}
                        isValid={true}
                        labelText={'What\'s the spouse benefit?'}
                        onChange={(e) => setState({...state, spouseBenefit: e.target.value})}
                    />
                    <DropDownField
                        id='LifeInsuranceChildBenefitDropdown'
                        options={childBenefitOptions}
                        value={state.childBenefit}
                        isValid={true}
                        labelText={'What\'s the child benefit?'}
                        onChange={(e) => setState({...state, childBenefit: e.target.value})}
                    />
                </div>
                <Button
                    className='pds-button pds-button-primary pds-modal-close-dialog'
                    onClick={() => {
                        GoogleAnalyticsUtil.modalClickAction('Life insurance', 'Submit');
                        dispatch(saveData(
                            {
                                type: UPDATE_LIFE_INSURANCE,
                                data: {
                                    employeeBenefitSchedule: state.employeeBenefitSchedule,
                                    waiverOfPremiumDuration: state.waiverOfPremiumDuration,
                                    maximumEmployeeBenefit: state.maximumEmployeeBenefit,
                                    spouseBenefit: state.spouseBenefit,
                                    childBenefit: state.childBenefit,
                                    product: 'Life'
                                }
                            }
                        ));
                    }}
                ><span className='sr-only'>Life insurance submit</span>Submit</Button>
            </Modal>
        </div>
    );
};

export default LifeInsurance;
