import React from 'react';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const EnterpriseFooter = () => {
    return <div className='container-fluid noPrint'>
        <footer className='pds-superFooter'>
            <div className='pds-superFooter-desktop row'>
                <div className='pds-superFooter-flex-group offset-sm-1 col-sm-3'>
                    <h6 className='pds-superFooter-label'>Our company</h6>
                    <nav aria-labelledby='Our company'>
                        <ul className='pds-superFooter-links'>
                            <li>
                                <a
                                    href='https://www.principal.com/about-us'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('About us')}
                                >
                                    About us
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://investors.principal.com'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Investor relations')}
                                >
                                    Investor relations
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://www.principal.com/about-us/news-room'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('News Room')}
                                >
                                    News room
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://www.principal.com/sustainability'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Sustainability')}
                                >
                                    Sustainability
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className='pds-superFooter-flex-group col-sm-3'>
                    <h6 className='pds-superFooter-label'>We're hiring</h6>
                    <nav aria-labelledby="We're hiring">
                        <ul className='pds-superFooter-links'>
                            <li>
                                <a 
                                    href='https://www.principal.com/about-us/careers'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Careers')}
                                >
                                    Careers
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://www.principal.com/about-us/careers/global-jobs'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Global jobs')}
                                >
                                    Global jobs
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://www.principal.com/about-us/careers/financial-representative-experienced-sales-professional'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Financial professional opportunities')}
                                >
                                    Financial professional opportunities
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://www.principal.com/about-us/careers/internships'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Internships')}
                                >
                                    Internships
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://www.principal.com/about-us/careers/recent-graduates'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Recent graduates')}
                                >
                                    Recent graduates
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className='pds-superFooter-flex-group col-sm-3'>
                    <h6 className='pds-superFooter-label'>Other sites</h6>
                    <nav aria-labelledby='Other sites'>
                        <ul className='pds-superFooter-links'>
                            <li>
                                <a
                                    href='https://www.principal.com/dental-providers'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('For dental providers')}
                                >
                                    For dental providers
                                </a>
                            </li>
                            <li>
                                <a
                                    href='https://advisors.principal.com'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('For financial professionals')}
                                >
                                    For financial professionals
                                </a>
                            </li>
                            <li>
                                <a
                                    href='http://www.principalglobal.com'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('For institutional investors')}
                                >
                                    For institutional investors
                                </a>
                            </li>
                            <li>
                                <a
                                    href='http://www.principalfunds.com'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Principal funds')}
                                >
                                    Principal funds
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className='pds-superFooter-flex-group col-md-2'>
                    <h6 className='pds-superFooter-label'>Get Help</h6>
                    <nav aria-labelledby='Get help'>
                        <ul className='pds-superFooter-links'>
                            <li>
                                <i className='fa fa-phone' aria-hidden='true'/>
                                <a
                                    href='tel:800-986-3343'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('800-986-3343')}
                                >
                                    800-986-3343
                                </a>
                            </li>
                            <li>
                                <i className='fa fa-question-circle' aria-hidden='true'/>
                                <a
                                    href='https://www.principal.com/help'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Help Topics')}
                                >
                                    Help Topics
                                </a>
                            </li>
                            <li>
                                <i className='fa fa-envelope' aria-hidden='true'/>
                                <a
                                    href='https://www.principal.com/contact-us'
                                    role='link'
                                    onClick={() => GoogleAnalyticsUtil.tocLink('Contact Us')}
                                >
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className='pds-superFooter-mobile'>
                <details className='pds-collapsible-details'>
                    <summary
                       className='pds-collapsible-summary pds-typography-h6'
                       onClick={() => GoogleAnalyticsUtil.textLink('Our company')}
                    >
                        Our company
                    </summary>
                    <div className='pds-collapsible-details-text'>
                        <nav aria-labelledby='Our company'>
                            <ul className='pds-superFooter-links'>
                                <li>
                                    <a
                                        href='https://www.principal.com/about-us'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('About us')}
                                    >
                                        About us
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://investors.principal.com'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Investor relations')}
                                    >
                                        Investor relations
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.principal.com/about-us/news-room'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('News room')}
                                    >
                                        News room
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.principal.com/sustainability'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Sustainability')}
                                    >
                                        Sustainability
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </details>
                <details className='pds-collapsible-details'>
                    <summary
                       className='pds-collapsible-summary pds-typography-h6'
                       onClick={() => GoogleAnalyticsUtil.textLink('We\'re hiring')}
                    >
                        We're hiring
                    </summary>
                    <div className='pds-collapsible-details-text'>
                        <nav aria-labelledby="We're hiring">
                            <ul className='pds-superFooter-links'>
                                <li>
                                    <a
                                        href='https://www.principal.com/about-us/careers'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Careers')}
                                    >
                                        Careers
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.principal.com/about-us/careers/global-jobs'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Global jobs')}
                                    >
                                        Global jobs
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.principal.com/about-us/careers/financial-representative-experienced-sales-professional'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Financial professional opportunities')}
                                    >
                                        Financial professional opportunities
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.principal.com/about-us/careers/internships'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Internships')}
                                    >
                                        Internships
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://www.principal.com/about-us/careers/recent-graduates'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Recent graduates')}
                                    >
                                        Recent graduates
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </details>
                <details className='pds-collapsible-details'>
                    <summary
                       className='pds-collapsible-summary pds-typography-h6'
                       onClick={() => GoogleAnalyticsUtil.textLink('Other sites')}
                    >
                        Other sites
                    </summary>
                    <div className='pds-collapsible-details-text'>
                        <nav aria-labelledby='Other sites'>
                            <ul className='pds-superFooter-links'>
                                <li>
                                    <a
                                        href='https://www.principal.com/dental-providers'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('For dental providers')}
                                    >For
                                        dental
                                        providers
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://advisors.principal.com'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('For financial professionals')}
                                    >
                                        For financial professionals
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='http://www.principalglobal.com'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('For institutional investors')}
                                    >
                                        For institutional investors
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='http://www.principalfunds.com'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Principal funds')}
                                    >
                                        Principal funds
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </details>
                <details className='pds-collapsible-details'>
                    <summary
                       className='pds-collapsible-summary pds-typography-h6'
                       onClick={() => GoogleAnalyticsUtil.textLink('Other sites')}
                    >
                        Get Help
                    </summary>
                    <div className='pds-collapsible-details-text'>
                        <nav aria-labelledby='Get Help'>
                            <ul className='pds-superFooter-links'>
                                <li>
                                    <i className='fa fa-phone' aria-hidden='true'/>
                                    <a
                                        href='tel:800-986-3343'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('800-986-3343')}
                                    >
                                        800-986-3343
                                    </a>
                                </li>
                                <li>
                                    <i className='fa fa-question-circle' aria-hidden='true'/>
                                    <a
                                        href='https://www.principal.com/help'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Help Topics')}
                                    >
                                        Help Topics
                                    </a>
                                </li>
                                <li>
                                    <i className='fa fa-envelope' aria-hidden='true'/>
                                    <a
                                        href='https://www.principal.com/contact-us'
                                        role='link'
                                        onClick={() => GoogleAnalyticsUtil.tocLink('Contact Us')}
                                    >
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </details>
            </div>
            <div className='pds-enterpriseFooter-light' data-settings-social='true' data-settings-espanol='false'/>
        </footer>
    </div>;
};

export default EnterpriseFooter;
