import {StateUtil} from '../util/StateUtil';

export class CompanyInfoFormValidator {
    static validate(state, setState) {
        const validationFields = {
            employeeRangeIsValid: this.fieldIsNotBlank(state.employeeRange),
            industryIsValid: this.fieldIsNotBlank(state.industry),
            roleIsValid: this.fieldIsNotBlank(state.role),
            zipCodeIsValid: this.validateZipCode(state.zipCode),
        };

        setState({...state, ...validationFields});
        return Object.values(validationFields).every(fieldIsValid => fieldIsValid);
    }

    static fieldIsNotBlank(field) {
        return Boolean(field);
    };

    static validateZipCode(zipCode) {
        return this.fieldIsNotBlank(zipCode) && zipCode.length === 5 && Boolean(StateUtil.getState(zipCode));
    };
}
