export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
const defaultState = {};

const companyInfoReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_COMPANY_INFO:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default companyInfoReducer;