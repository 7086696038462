import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Tooltip = (props) => {
    return <Tippy content={<span>{props.content}</span>}>
        <span><i data-feather='info' stroke='#045FA8' aria-hidden={true}  style={{height: '15px'}}/></span>
    </Tippy>;
};

export default Tooltip;