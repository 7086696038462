export const UPDATE_RESULTS = 'UPDATE_RESULTS';
const defaultState = {error: false, loading: true};

const resultsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_RESULTS:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default resultsReducer;