export const RANGE_ONE = '3-9';
export const RANGE_TWO = '10-24';
export const RANGE_THREE = '25-49';
export const RANGE_FOUR = '50-100';

export const employeeRanges = [
    {display: 'Please select', value: ''},
    {display: RANGE_ONE, value: RANGE_ONE},
    {display: RANGE_TWO, value: RANGE_TWO},
    {display: RANGE_THREE, value: RANGE_THREE},
    {display: RANGE_FOUR, value: RANGE_FOUR}
];

