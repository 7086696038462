import RequiredFieldIndicator from './RequiredFieldIndicator';
import FormError from './FormError';
import React from 'react';

const Input = (props) => {
    return (
        <div style={{maxWidth: '400px', marginBottom: '20px'}}>
            <label id={props.id}>
                {props.labelText}
                {props.isRequired && <RequiredFieldIndicator/>}
                {props.tooltip && props.tooltip}
                <input
                    className='form-control'
                    maxLength={props.maxLength}
                    type='text'
                    autoComplete='new-password'
                    data-initialized='true'
                    value={props.value}
                    onChange={props.onChange}
                />
                {!props.isValid &&
                    <FormError errorText={props.errorText}/>
                }
            </label>
        </div>
    );
};

export default Input;
