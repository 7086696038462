import React from 'react';
import {GoogleAnalyticsUtil} from '../../../util/GoogleAnalyticsUtil';
const Modal = (props) => {

    return (
        <div className='pds-modal' id={`${props.id}-pds-modal`}>
            <button
                type='button'
                style={{marginBottom: '15px'}}
                className='pds-button pds-button-secondary pds-modal-open-dialog'
                onClick={() => {
                    GoogleAnalyticsUtil.modalViewAction(props.title);
                }}
            >
                <span className='sr-only'>
                    {props.srText}
                </span>
                {props.buttonText}
            </button>
            <div className='pds-modal-dialog pds-modal-large' role='dialog' aria-labelledby={`${props.id}-modal-title`} aria-hidden='true'>
                <button className='pds-modal-close-dialog pds-modal-close' type='button' aria-label='Close'>
                    <i data-feather='x' aria-hidden='true'/>
                </button>
                <h1
                    id={`${props.id}-modal-title`}
                    className='sr-only'
                >
                    {props.headerText}
                </h1>
                <h3 className='pds-typography-bold'>{props.headerText}</h3>
                <p>Tell us more about what you currently offer.</p>
                <div className='pds-modal-cta'>{props.children}</div>
            </div>
        </div>
    );
};

export default Modal;
