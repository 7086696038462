export const UPDATE_LIFE_INSURANCE = 'UPDATE_LIFE_INSURANCE';
const defaultState = {};

const lifeInsuranceReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_LIFE_INSURANCE:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default lifeInsuranceReducer;