import axios from 'axios';
import {getSettings} from './SettingsRepository';

export const getData = async (queryParams) => {
    const settings = await getSettings();
    const response = await axios.get(
        settings.url + '/BenefitDesignToolApi?id=' + queryParams.id
        + '&employeeRange=' + queryParams.employeeRange
        + '&industry=' + queryParams.industry
        + '&region=' + queryParams.region
        + '&role=' + queryParams.role
    );
    return response.data;
};

export const postData = async (data) => {
    const settings = await getSettings();
    const response = await axios.post(
        `${settings.url}/BenefitDesignToolApi/save`,
        data
    );
    return response.status;
};