import React from 'react';

const ErrorAlert = (props) => {

    return (
        <div className='pds-alert pds-alert-error'>
            <div className='pds-card-section'>
                <i data-feather='alert-triangle' className='pds-alert-type-icon' aria-hidden='true'/>
                <div className='pds-alert-content' role='alert'>
                    <span className='sr-only'>Error. </span>
                    <span id='ErrorAlertText'>{props.errorText}</span>
                </div>
            </div>
        </div>
    );
};

export default ErrorAlert;
