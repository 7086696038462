import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ErrorPage from '../component/ErrorPage';
import Welcome from '../component/view/Welcome';


export const bdtBaseUrl = 'https://www.principal.com/businesses/compare-benefits';
export const redirectMethod = () => {
    window.location.href = bdtBaseUrl;
    return null;
};

const Routes = () => {
    return (
        <Switch>
            <Route component={ErrorPage} path='/error'/>
            <Route component={Welcome} path='/benefit-selection'/>
            <Route component={redirectMethod} path='/'/>
        </Switch>
    );
};

export default Routes;