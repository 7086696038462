import React from 'react';
import ErrorAlert from './ErrorAlert';
import BarGraph from './BarGraph';
import BenefitCallout from './BenefitCallout';
import {GoogleAnalyticsUtil} from '../../../util/GoogleAnalyticsUtil';

const CoverageDropdown = (props) => {

    const doNotDisplayChartsForTheseFields = [
        'automaticEnrollment',
        'automaticEnrollmentSweep',
        'automaticEscalation',
        'employerMatch'
    ];

    const render = () => {
        return <div className='col-md-12' style={{marginBottom: '5px', paddingLeft: 0, paddingRight: 0}}>
            {props.loading
                ? renderSkeleton()
                : <details
                    className='pds-collapsible-details'
                    style={{borderStyle: 'ridge', borderWidth: '1px'}}
                    open={props.isActive}>
                    <summary
                        className='pds-collapsible-summary'
                        data-toggle='collapse'
                        onClick={() => GoogleAnalyticsUtil.callToAction(props.title)}>
                        {props.title}
                    </summary>
                    <div className='pds-collapsible-details-text' style={{paddingRight: '16px', paddingLeft: '16px'}}>
                        <dl className='pds-list-description'>
                            {Boolean(props.results)
                                ? Object.keys(props.results).map(key =>
                                    <>
                                        <dt>{formatLabel(key)}</dt>
                                        <dd>Others: {props.results[key]}</dd>
                                        {Boolean(props.answers && props.answers.hasOwnProperty(key)) &&
                                            <dd>You: {props.answers[key]}</dd>
                                        }
                                        {(props.results[key].includes('$') || props.results[key].includes('%'))
                                            && !(doNotDisplayChartsForTheseFields.includes(key))
                                            && <BarGraph
                                                userAnswers={props.answers[key]}
                                                apiResults={props.results[key]}
                                            />}
                                        <BenefitCallout
                                            benefit={key}
                                            coverage={props.title}
                                            answer={props.answers[key]}
                                            result={props.results[key]}
                                        />
                                        <hr/>
                                    </>)
                                : <ErrorAlert
                                    errorText='No results found.'
                                />
                            }
                        </dl>
                        <span>{props.children}</span>
                    </div>
                </details>}
        </div>;
    };

    const renderSkeleton = () => {
        return <details className='' style={{borderStyle: 'ridge', borderWidth: '1px'}}>
            <summary className='pds-collapsible-summary'>
                <div className='pds-skeletonLoader pds-skeletonLoader-heading col-md-8' aria-busy='true'
                     aria-live='polite'/>
            </summary>
        </details>;
    };

    const formatLabel = (key) => {
        let label = key
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .toLowerCase();
        return label.charAt(0).toUpperCase() + label.slice(1);
    };

    return render();
};

export default CoverageDropdown;