export const NONE = 'None';
export const TWO_YEARS = '2 years';
export const FIVE_YEARS = '5 years';
export const TEN_YEARS = '10 years';
export const TO_AGE_SIXTY_FIVE = 'To age 65';
export const TO_AGE_SEVENTY = 'To age 70';

export const waiverDurations = [
    {display: 'Please select', value: ''},
    {display: NONE, value: NONE},
    {display: TWO_YEARS, value: TWO_YEARS},
    {display: FIVE_YEARS, value: FIVE_YEARS},
    {display: TEN_YEARS, value: TEN_YEARS},
    {display: TO_AGE_SIXTY_FIVE, value: TO_AGE_SIXTY_FIVE},
    {display: TO_AGE_SEVENTY, value: TO_AGE_SEVENTY}
];

