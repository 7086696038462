export const UPDATE_SHORT_TERM_DISABILITY = 'UPDATE_SHORT_TERM_DISABILITY';
const defaultState = {};

const shortTermDisabilityReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_SHORT_TERM_DISABILITY:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

export default shortTermDisabilityReducer;