export const TWENTY_PERCENT = '20%';
export const THIRTY_PERCENT = '30%';
export const FORTY_PERCENT = '40%';
export const FIFTY_PERCENT = '50%';
export const SIXTY_PERCENT = '60%';
export const TWO_THIRDS_PERCENT = '66 and 2/3%';
export const SEVENTY_PERCENT = '70%';

export const benefitPercentageOptions = [
    {display: 'Please select', value: ''},
    {display: TWENTY_PERCENT, value: TWENTY_PERCENT},
    {display: THIRTY_PERCENT, value: THIRTY_PERCENT},
    {display: FORTY_PERCENT, value: FORTY_PERCENT},
    {display: FIFTY_PERCENT, value: FIFTY_PERCENT},
    {display: SIXTY_PERCENT, value: SIXTY_PERCENT},
    {display: TWO_THIRDS_PERCENT, value: TWO_THIRDS_PERCENT},
    {display: SEVENTY_PERCENT, value: SEVENTY_PERCENT}
];